import { useStore } from 'utils/hooks/store';

import { EntityModules } from 'core/constants';

import { getEntityTranslation, Translation } from 'stores/EntityDetails/utils';
import { EntitiesStores } from 'components/Entities/types';

type RelatedEntityDetailsProps = {
  store: EntitiesStores;
  translations: Translation;
  nameKey: string;
  entityType: EntityModules;
};

type BasicValuesProps = Record<EntityModules, Omit<RelatedEntityDetailsProps, 'store'>>;

export const basicValues: BasicValuesProps = {
  [EntityModules.Assets]: {
    translations: getEntityTranslation.assets,
    nameKey: 'assetName',
    entityType: EntityModules.Assets,
  },
  [EntityModules.Sites]: {
    translations: getEntityTranslation.sites,
    nameKey: 'siteName',
    entityType: EntityModules.Sites,
  },
  [EntityModules.Suppliers]: {
    translations: getEntityTranslation.suppliers,
    nameKey: 'supplierName',
    entityType: EntityModules.Suppliers,
  },
  [EntityModules.Risks]: {
    translations: getEntityTranslation.risks,
    nameKey: 'riskName',
    entityType: EntityModules.Risks,
  },
  [EntityModules.Incidents]: {
    translations: getEntityTranslation.incidents,
    nameKey: 'incidentName',
    entityType: EntityModules.Incidents,
  },
};

export const getRelatedEntityDetails = (url: string): RelatedEntityDetailsProps => {
  const module = url.split('/')[1] as EntityModules;
  const stores = useStore();

  switch (module) {
    case EntityModules.Sites:
      return { store: stores.siteDetails, ...basicValues[EntityModules.Sites] };
    case EntityModules.Assets:
      return { store: stores.assetDetails, ...basicValues[EntityModules.Assets] };
    // TODO: Add the rest of the modules after implementing the stores
    default:
      return { store: stores.siteDetails, ...basicValues[EntityModules.Sites] };
  }
};
