import React from 'react';

import { GridColumnVisibilityModel, GridFilterModel, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';

import LinearProgress from '@mui/material/LinearProgress';

import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';

import { isEqual } from 'lodash';

import { VatixError } from 'vatix-ui/lib/components/Error/types';

import { FieldResponse, ProtectorType } from 'utils/api/types';

import { useLocalStorage } from 'utils/hooks/localStorage';

import { Container, StyledPagination, StyledDataGrid, GridContainer } from './styles';

import CustomToolbar from './components/Toolbar/Toolbar';
import FilterMenu from './components/FilterMenu';
import ColumnsMenu from './components/ColumnsMenu';

import { ColumnDefinition, FilterType, GridProps, GridProtectorType, GridState, PaginationType } from './types';
import { defaultColumnProps, defaultFormatters, defaultOperators, defaultRenderers, getFilters } from './utils';
import NoRowsOverlay from './components/NoRowsOverlay';

const Grid = ({
  basicColumns,
  filters = [],
  header,
  onRowClick,
  operators = defaultOperators,
  onError,
  onRefreshCallback,
  renderers = defaultRenderers,
  formatters = defaultFormatters,
  sortBy,
  dataURL,
  fieldsURL,
  checkboxSelection,
  transformData,
  showQuickFilter = true,
  showHeader = true,
  initialData,
  withoutBorderRadius = false,
  customNoRowsText,
  entity,
  gridPagination = true,
  disableColumnSelector,
  disableColumnFilter,
  disableColumnMenu,
  disableSelectAllColumns = false,
  sortingMapping,
  temporaryFilters,
  disableExtraFields,
  pageSize = 20,
  onlyBasicExport = true,
}: GridProps): React.ReactElement => {
  const realColumns = basicColumns.filter((c) => !c.customColumn);
  const basicColumnsDefinitionWithoutCustom = realColumns.map((columnProps) => ({
    ...defaultColumnProps,
    ...columnProps,
  })) as ColumnDefinition[];
  const basicColumnsDefinition = basicColumns.map((columnProps) => ({
    ...defaultColumnProps,
    valueGetter: columnProps.renderCell ? ({ value }) => ({ value }) : undefined,
    ...columnProps,
  })) as ColumnDefinition[];
  const basicColumnsVisibility = Object.fromEntries(
    basicColumnsDefinitionWithoutCustom.map((c) => [c.field, c.defaultVisibility !== false])
  );
  const defaultState = (): GridState => ({
    columns: basicColumnsVisibility,
    filters,
    quickSearch: '',
    sorting: sortBy ? [sortBy] : [],
    pagination: {
      page: 0,
      pageSize,
    },
  });

  const [state, setState, removeState] = useLocalStorage(`grid_state_${dataURL}`, defaultState());

  const columnsLoadedRef = React.useRef(false);
  const [data, setData] = React.useState<GridValidRowModel[] | null>(null);
  const [columnsDefinition, setColumnsDefinition] = React.useState(basicColumnsDefinitionWithoutCustom);

  const [rowCount, setRowCount] = React.useState(0);
  const [loading, setLoading] = React.useState(!initialData);
  const [isFilterActive, setFilterActive] = React.useState(false);
  const [filterButtonEl, setFilterButtonEl] = React.useState(null);
  const [columnsButtonEl, setColumnsButtonEl] = React.useState(null);

  const [currentUrl, setCurrentUrl] = React.useState(dataURL);

  CustomToolbar.propTypes = {
    // @ts-ignore
    setFilterButtonEl: PropTypes.func.isRequired,
  };

  React.useEffect(() => {
    (async () => {
      if (!fieldsURL) {
        setColumnsDefinition(basicColumnsDefinitionWithoutCustom);
        columnsLoadedRef.current = true;
        return;
      }
      const url = new URL(fieldsURL, process.env.REACT_APP_API_URL);
      url.searchParams.append('limit', '1000');
      const response = await fetch(url.toString(), {
        credentials: 'include',
      });
      const customColumns = (await response.json()).results as FieldResponse[];
      const newCustomColumnDefinitions = customColumns
        .filter((c) => Object.values(ProtectorType).includes(c.schema?.protectorType as ProtectorType))
        .sort((a, b) => a.path.length - b.path.length)
        .map(
          (c) =>
            (({
              ...defaultColumnProps,
              field: c.path.join('->'),
              headerName: c.path.slice(-1)[0],
              valueGetter: ({ row: { customFields } }: { row: { customFields: Record<string, string> } }) =>
                (customFields || {})[c.path.join('->')],
              renderCell: renderers[c.schema.protectorType as GridProtectorType],
              valueFormatter: formatters[c.schema.protectorType as GridProtectorType],
              // if protectorType is equal date use default date_time operators,
              // because filtering by empty is not supported for custom incidents fields
              filterOperators:
                c.schema.protectorType === ProtectorType.Date
                  ? defaultOperators.date_time()
                  : operators[c.schema.protectorType as GridProtectorType]({ schema: c.schema }),
              schema: c.schema,
              customColumn: true,
            } as unknown) as ColumnDefinition)
        );

      setState((prevState) => ({
        ...prevState,
        columns: {
          ...state.columns,
          ...Object.fromEntries(
            newCustomColumnDefinitions.map((c) => [
              c.field,
              state.columns[c.field] ?? basicColumns.some((column) => column.field === c.field && column.customColumn),
            ])
          ),
        },
      }));
      if (disableExtraFields) {
        setColumnsDefinition([
          ...basicColumnsDefinition.map(
            (column) => newCustomColumnDefinitions.find((newColumn) => newColumn.field === column.field) || column
          ),
        ]);
      } else {
        setColumnsDefinition([
          ...basicColumnsDefinition.map(
            (column) => newCustomColumnDefinitions.find((newColumn) => newColumn.field === column.field) || column
          ),
          ...newCustomColumnDefinitions.filter(
            (newColumn) => !basicColumnsDefinition.some((c) => c.field === newColumn.field)
          ),
        ]);
      }

      columnsLoadedRef.current = true;
    })();
  }, []);

  React.useEffect(() => {
    if (initialData) {
      setRowCount(initialData.count);
      setData(initialData.results);
    }
  }, [initialData]);

  const refreshData = React.useCallback(
    // @ts-ignore
    async (filtering, quickSearch, pagination, sorting, customColumns, customColumnsDefinition, temporaryFiltering) => {
      if (initialData) {
        return;
      }
      setLoading(true);
      const getColumnByKey = (key: string): { nativeFilter: boolean | string; sortingField?: string } | null => {
        if (sortingMapping?.[key]) {
          return { nativeFilter: false, sortingField: sortingMapping[key] };
        }
        return customColumnsDefinition.find((c: ColumnDefinition) => c.field === key);
      };
      const url = new URL(dataURL, process.env.REACT_APP_API_URL);
      const customColumnsEncoded = Object.entries(customColumns)
        .filter(([key, value]) => value && !realColumns.some((c) => key === c.field))
        .map(([key]) => encodeURIComponent(key));

      if (customColumnsEncoded.length) {
        url.searchParams.append('fields', customColumnsEncoded.join(','));
      }

      const keyValueFilters = Object.entries(getFilters(filtering) || {});

      const customFilters = keyValueFilters.filter(
        ([key]) => !customColumnsDefinition.find((c: ColumnDefinition) => c.field === key)?.nativeFilter
      );

      if (customFilters.length) {
        url.searchParams.append(
          'custom',
          customFilters.map(([key, value]) => `${encodeURIComponent(key)}>>${value}`).join(',')
        );
      }
      const getColumnNameForFiltering = (key: string): string => {
        const nativeFilter = customColumnsDefinition.find((c: ColumnDefinition) => c.field === key)?.nativeFilter;
        return nativeFilter === true ? key : nativeFilter;
      };

      keyValueFilters
        .map(([key, value]) => [getColumnNameForFiltering(key), value])
        .filter(([key]) => key !== 'filters' && Boolean(key))
        .forEach(([key, value]) => url.searchParams.append(key, value as string));

      if (temporaryFiltering) {
        (temporaryFiltering as FilterType[]).forEach((filter) => {
          url.searchParams.append(filter.field, filter.value);
        });
      }

      const [sort] = sorting;
      if (sort) {
        url.searchParams.append(
          'ordering',
          `${sort.sort === 'desc' ? '-' : ''}${getColumnByKey(sort.field)?.sortingField || sort.field}`
        );
      }

      url.searchParams.append('limit', pagination.pageSize.toString());
      url.searchParams.append('offset', (pagination.pageSize * pagination.page).toString());

      if (quickSearch) {
        url.searchParams.append('query', quickSearch.trim());
      }

      setCurrentUrl(url.toString());
      const response = await fetch(url.toString(), {
        credentials: 'include',
      });
      if (!response.ok && onError) {
        if (response.status === 401) {
          onError(VatixError.Unauthorized);
        } else if (response.status === 404) {
          removeState();
          onError(VatixError.NotFound);
        } else {
          removeState();
          onError(VatixError.SystemError);
        }
      }
      if (transformData) {
        const val = await response.json();
        const { count, results } = transformData(val);
        setRowCount(count);
        setData(results);
      } else {
        const { count, results } = await response.json();
        setRowCount(count);
        setData(results);
      }
      setLoading(false);
    },
    []
  );

  const debounced = React.useMemo(() => debounce(refreshData, 500), []);
  if (onRefreshCallback) {
    onRefreshCallback(() =>
      debounced(
        state.filters,
        state.quickSearch,
        state.pagination,
        state.sorting,
        state.columns,
        columnsDefinition,
        temporaryFilters
      )
    );
  }

  React.useEffect(() => {
    (async () => {
      debounced(
        state.filters,
        state.quickSearch,
        state.pagination,
        state.sorting,
        state.columns,
        columnsDefinition,
        temporaryFilters
      );
    })();
  }, [state.columns, state.filters, state.quickSearch, state.sorting, state.pagination, temporaryFilters]);

  const onFilterModelChange = (model: GridFilterModel): void => {
    if (columnsLoadedRef.current && !isEqual(getFilters(state.filters), getFilters(model.items as FilterType[]))) {
      setState((prevState) => ({
        ...prevState,
        filters: model.items as FilterType[],
      }));
    }

    const quickSearch = model?.quickFilterValues?.join(' ') as string;

    if (model.quickFilterValues && quickSearch !== state.quickSearch) {
      setState((prevState) => ({
        ...prevState,
        quickSearch,
      }));
    }
  };

  const onColumnVisibilityModelChange = (newColumns: GridColumnVisibilityModel): void =>
    setState((prevState) => ({ ...prevState, columns: newColumns }));
  const onSortModelChange = (sorting: GridSortModel): void => setState((prevState) => ({ ...prevState, sorting }));

  // actions column should be always at the end
  const findActionColumn = (obj: ColumnDefinition): boolean => obj.type === 'actions';

  columnsDefinition.push(columnsDefinition.splice(columnsDefinition.findIndex(findActionColumn), 1)[0]);

  return (
    <Container $withoutBorderRadius={withoutBorderRadius}>
      {header}
      <GridContainer>
        <StyledDataGrid
          disableRowSelectionOnClick
          autoHeight
          disableVirtualization
          filterMode="server"
          hideFooter={data?.length === 0 || !gridPagination}
          onFilterModelChange={onFilterModelChange}
          disableColumnMenu={disableColumnMenu}
          disableColumnFilter={disableColumnFilter}
          disableColumnSelector={disableColumnSelector}
          columnVisibilityModel={state.columns}
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
          sortModel={state.sorting}
          onSortModelChange={onSortModelChange}
          rows={data || []}
          columns={columnsDefinition.map(
            (definition) =>
              (({
                ...definition,
                actions: {
                  refreshData: () => {
                    refreshData(
                      state.filters,
                      state.quickSearch,
                      state.pagination,
                      state.sorting,
                      state.columns,
                      basicColumnsDefinition,
                      temporaryFilters
                    );
                  },
                },
              } as unknown) as ColumnDefinition)
          )}
          onRowClick={onRowClick}
          getRowId={(row) => row.uuid}
          loading={loading}
          filterModel={{ items: state.filters, quickFilterValues: [state.quickSearch] }}
          pagination={gridPagination}
          pageSizeOptions={[5, 10, 20, 100]}
          rowCount={rowCount}
          paginationMode="server"
          paginationModel={state.pagination}
          onPaginationModelChange={(pagination) =>
            setState((prevState) => ({ ...prevState, pagination: pagination as PaginationType }))
          }
          slots={{
            noRowsOverlay: () => <NoRowsOverlay entity={entity} customText={customNoRowsText} />,
            toolbar: CustomToolbar,
            loadingOverlay: LinearProgress,
            pagination: StyledPagination,
            columnsPanel: () => <ColumnsMenu disableSelectAllColumns={disableSelectAllColumns} />,
            filterPanel: FilterMenu,
          }}
          slotProps={{
            columnHeaderFilterIconButton: {
              counter: 0,
            },
            toolbar: {
              showHeader,
              showQuickFilter,
              setColumnsButtonEl,
              setFilterButtonEl,
              setFilterActive,
              dataURL: currentUrl,
              columnsState: state.columns,
              columnsDefinition,
              onlyBasicExport,
            },
            pagination: {
              sx: {
                '& .MuiTablePagination-menuItem': {
                  fontSize: '14px',
                },
              },
              SelectProps: {
                MenuProps: {
                  PaperProps: {
                    sx: {
                      '& .MuiTablePagination-menuItem': {
                        fontSize: '14px',
                      },
                    },
                  },
                },
              },
            },
            panel: {
              // @ts-ignore
              anchorEl: isFilterActive ? filterButtonEl : columnsButtonEl,
              placement: 'auto',
              sx: {
                '& .MuiPaper-root': {
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
              },
            },
          }}
          sortingMode="server"
          columnBuffer={8}
          checkboxSelection={checkboxSelection}
        />
      </GridContainer>
    </Container>
  );
};

export default Grid;
