import React from 'react';

import { GridEventListener, GridRowParams } from '@mui/x-data-grid';

import { reverse } from 'named-urls';

import Grid from 'components/Grid';

import { ColumnDefinition } from 'components/Grid/types';

import { useStore } from 'utils/hooks/store';

import routes from 'core/routes';

import { formatDateForDisplay } from 'utils/formatters/time';

import EntityActionCell from 'components/Entities/EntityActionCell';

import { AssetsTableProps } from './types';
import { StyledCreated } from './styles';

export const basicColumns = ([
  {
    field: 'assetName',
    headerName: 'Name',
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    renderCell: ({ value }: { value: string }) => <StyledCreated>{formatDateForDisplay(value)}</StyledCreated>,
  },
] as unknown) as ColumnDefinition[];

const AssetsTable: React.FunctionComponent<AssetsTableProps> = ({ onError, onRefreshCallback }): React.ReactElement => {
  const { routing, assetDetails } = useStore();

  const onRowClick: GridEventListener<'rowClick'> = (item: GridRowParams): void => {
    routing.push(reverse(routes.dashboard.assets.details, { assetId: item.id }));
  };

  return (
    <Grid
      basicColumns={
        ([
          ...basicColumns,
          {
            field: 'actions',
            type: 'actions',
            headerName: '',
            maxWidth: 50,
            ariaLabel: 'actions_cell',
            // eslint-disable-next-line react/no-unused-prop-types
            renderCell: ({ row, colDef }: { row: { uuid: string }; colDef: ColumnDefinition }) => (
              <EntityActionCell rowData={row} actions={colDef.actions} store={assetDetails} />
            ),
            filterOperators: null,
            sortable: false,
          },
        ] as unknown) as ColumnDefinition[]
      }
      entity="assets"
      onError={onError}
      dataURL="/api/entities/assets/"
      customNoRowsText="No assets have been created yet"
      onRowClick={onRowClick}
      onRefreshCallback={onRefreshCallback}
    />
  );
};

export default AssetsTable;
