import { action, observable, runInAction, makeObservable } from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import { AxiosError } from 'axios';

import Logger from 'vatix-ui/lib/utils/logger';

import { reverse } from 'named-urls';

import API from 'utils/api';
import { EventType } from 'utils/events/constants';
import { EntityValueType, EntityDetailsType } from 'utils/api/types';
import { isNotFound } from 'utils/api/errors';

import { EntityModules } from 'core/constants';

import routes from 'core/routes';

import EntitiesActivities from 'stores/EntitiesActivities';

import RootStore from '../Root';
import EntityDetails from '../EntityDetails/EntityDetails';

export default class AssetsDetailsStore extends EntityDetails {
  @observable error?: AxiosError;

  @observable details?: EntityDetailsType;

  constructor(rootStore: RootStore, api: typeof API) {
    super(rootStore, api, EntityModules.Assets, routes.dashboard.assets.toString(), (uuid: string): string =>
      reverse(routes.dashboard.assets.details, { assetId: uuid })
    );

    makeObservable(this);

    eventBus.register(this);
  }

  @action.bound
  async loadDetails(assetId: string): Promise<void> {
    this.isLoaded = false;
    try {
      const { data } = await this.api.loadEntitiesDetails(this.entityType, assetId)();
      runInAction(() => {
        this.activities = new EntitiesActivities(this.store, this.api, assetId, this.entityType);
        this.error = undefined;
        this.details = data;
      });
    } catch (e) {
      runInAction(() => {
        // @ts-ignore
        this.error = e;
      });
      // @ts-ignore
      if (!isNotFound(e)) {
        Logger.error(`Invalid load asset details API response. Task id: ${assetId}`, e);
      }
    } finally {
      runInAction(() => {
        this.isLoaded = true;
      });
    }
  }

  create = async (assetName: string): Promise<boolean> => {
    try {
      await this.api.createEntity(this.entityType, { assetName })();
      return true;
    } catch (e) {
      Logger.error('Failed to create asset', e);

      return false;
    }
  };

  delete = async (assetId: string): Promise<boolean> => {
    try {
      await this.api.deleteEntity(this.entityType, assetId)();
    } catch (e) {
      Logger.error('Failed to delete asset', e);
      return false;
    }
    return true;
  };

  edit = async (data: Record<string, EntityValueType>): Promise<boolean> => {
    if (this.details?.uuid === undefined) {
      return false;
    }
    try {
      const edited = await this.api.editEntity(this.entityType, this.details?.uuid, data)();
      this.details = edited.data;
      this.store.notification.enqueueSuccessSnackbar('Asset edited successfully');
      return true;
    } catch (e) {
      const error = e as AxiosError;
      this.store.notification.enqueueErrorSnackbar(
        error.response?.data[0] || `The asset could not be updated. Something went wrong.`
      );
      Logger.error('Failed to edit asset', e);
      return false;
    }
  };

  @subscribe(EventType.LoggedOut)
  @action
  reset(): void {
    this.isLoaded = false;
    this.error = undefined;
    this.details = undefined;
  }

  // TODO: Implement this method when the licensing is ready
  // @action.bound
  // async loadCount(): Promise<number> {
  //   this.isLoaded = false;
  //   try {
  //     const { data } = await this.api.loadAssetsCount()();
  //     runInAction(() => {
  //       this.error = undefined;
  //     });
  //     return data.count;
  //   } catch (e) {
  //     runInAction(() => {
  //       // @ts-ignore
  //       this.error = e;
  //     });
  //     return 0;
  //   } finally {
  //     runInAction(() => {
  //       this.isLoaded = true;
  //     });
  //   }
  // }
}
